import React from "react";
import "./home.css";
import fajitas from "../pictures/fajitas.jpg";
import bricka from "../pictures/bricka.png";
import white_skull from "../pictures/white_skull.png";
import red_skull from "../pictures/red_skull.png";
import color_skull from "../pictures/color_skull.png";
import burrito_NY_1 from "../pictures/burrito_NY_1.png";
import pink_skull from "../pictures/pink_skull.png";
import tacos from "../pictures/tacos.png";
import map from "../pictures/map.png";
import fb_NY from "../pictures/fb_NY.png";
import instagram from "../pictures/instagram.png";
import tripadvisor from "../pictures/tripadvisor.png";
import tripadvisor_white from "../pictures/tripadvisor_white.png";


export class Home extends React.Component {
    render() {
        return(
            <div>
                <div className="picture"></div>

                <div className="container">            
                    <div className="row">
                            <div className="col-md-12">
                                <h2  className="header" id="nosotros">Bienvenido a <br/>La Casita Mexicana!</h2>
                                {/* <p className="subheader">El restaurante mexicana cerca de ti!</p> */}
                                <p className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin viverra libero sed sapien rutrum tempor. Donec vel luctus sem. Integer sed 
                                est nec urna volutpat lacinia dictum molestie turpis. Donec auctor sapien non volutpat auctor. Praesent in est ornare, finibus enim nec, vehicula purus. 
                                Integer vitae imperdiet ipsum. Vestibulum quis euismod nunc, pharetra ultricies nibh. </p>
                            </div>
                                
                    </div>
                </div>

                <div className="picture1">
                    <h3 className="container subheader" id="menu">Menu</h3>
                </div>

                <div className="container">
                    <h2 className="header1">Nuestras entradas</h2>

                    <div className="row">                      
                        <div className="col-md-5"><span className="subheader1">Nachos vegetarianos</span> <br/>
                        <span className="text"> Totopos con deliciosa salsa guacamole, queso Cheddar, jalapeños u crema agria. </span></div>
                        <div className="col-md-1 price">7,95</div>

                        <div className="col-md-5"><span className="subheader1">Nachos con pollo</span> <br/>
                        <span className="text"> Totopos con deliciosa salsa guacamole, queso Cheddar, jalapeños u crema agria. </span></div>

                        <div className="col-md-1 price">8,95</div>

                        <div className="col-md-5"><span className="subheader1">Nachos rancheros con carne picada</span> <br/>
                        <span className="text"> Totopos con deliciosa salsa guacamole, queso Cheddar, jalapeños u crema agria. </span></div>
                        <div className="col-md-1 price">9,95</div>

                        <div className="col-md-5"><span className="subheader1">Chille jalapeño rellenos de queso, empanados y frito</span> <br/></div>
                        <div className="col-md-1 price">6,95</div>   

                        <div className="col-md-5"><span className="subheader1">Alitas de pollo</span> <br/></div>
                        <div className="col-md-1 price">6,95</div>  

                        <div className="col-md-5"><span className="subheader1">Gambas rebozadas</span> <br/></div>
                        <div className="col-md-1 price">6,95</div>         
                    </div> 

                    <div className="row">
                        <div className="divider">
                            <div className="col-md-12"></div>     
                                <div>
                                    <img className="icon" src={white_skull}/>
                                </div>
                        </div>
                    </div>

                    <h2 className="header1">Nuestras ensaladas</h2>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Ensalada mixta</span> <br/>
                        <span className="text">Ensalada clásica que lleva lechuga fresca, tomates, pepino, cebolla y nuestro toque mexicano de maíz dulce. </span></div>
                        <div className="col-md-1 price">5,50</div>

                        <div className="col-md-5"><span className="subheader1">Ensalada cesar</span> <br/>
                        <span className="text">Lechuga fresca, tomate, tiras de pollo a la plancha, acompañada con una salsa de Césae aparte. </span></div>
                        <div className="col-md-1 price">8,50</div>
                    </div>

                    <div className="row">
                        <div className="divider">
                            <div className="col-md-12"></div>     
                                <div>
                                    <img className="icon" src={red_skull}/>
                                </div>
                        </div>
                    </div>

                    <h2 className="header1">Platos principales</h2>
                    <div className="col-md-4"><span className="subheader2">Fajitas</span></div>


                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Fajitas de pollo</span> <br/>
                        <span className="text">Tiras de pollo asado acompañadas de tortillas de trigo con pimiento rojo, pimiento verde y cebolla y salsas caseras.</span></div>
                        <div className="col-md-1 price">12,95</div>

                        <div className="col-md-5"><span className="subheader1">Fajitas de verduras</span> <br/>
                        <span className="text">Verduras salteadas acompañadas de tortillas de trigo con pimiento rojo, pimiento verde y cebolla y salsa caseras.</span></div>
                        <div className="col-md-1 price">10,95</div>

                        <div className="col-md-5"><span className="subheader1">Fajitas de gambas</span> <br/>
                        <span className="text">Gambas salteadas con pimiento rojo, pimiento verde y cebolla, acompañadas de tortillas de trigo y salsa casera.</span></div>
                        <div className="col-md-1 price">14,95</div>
                    </div> <br/><br/>

                    <div className="col-md-4"><span className="subheader2">Burritos</span> <br/>
                    <span className="text">Tortilla de trigo, gratinada con queso, rellena de arroz, frijoles y salsa.</span></div> <br/>


                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Burrito de verduras</span></div> <br/>
                        <div className="col-md-1 price">10,95</div>

                        <div className="col-md-5"><span className="subheader1">Burrito de de pollo</span></div> <br/>
                        <div className="col-md-1 price">12,95</div>

                        <div className="col-md-5"><span className="subheader1">Burrito de de ternera</span></div> <br/>
                        <div className="col-md-1 price">13,95</div>
                    </div> 

                    <div className="col-md-4"><span className="subheader1">Chilli con carne</span></div>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Chili con carne (especialidad de la casa)</span> <br/>
                        <span className="text">Receta de la abuela. Deliciosa carne preparada con alubias, servida con arroz y gratinada con queso.</span></div>
                        <div className="col-md-1 price">12,95</div>
                    </div> <br/><br/>

                    <div className="col-md-4"><span className="subheader2">Quesadilla</span> <br/>
                    <span className="text">Tortilla de trigo, doblado por la mitad, rellena de queso fundido, servida de salsa caseras.</span></div> <br/>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Quesadilla de verduras</span></div> <br/>
                        <div className="col-md-1 price">10,95</div>

                        <div className="col-md-5"><span className="subheader1">Quesadilla de pollo</span></div> <br/>
                        <div className="col-md-1 price">12,95</div>

                        <div className="col-md-5"><span className="subheader1">Quesadilla de ternera</span></div> <br/>
                        <div className="col-md-1 price">13,95</div>
                    </div> <br/><br/>

                    <div className="col-md-4"><span className="subheader2">Tacos</span></div>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Tacos de verduras</span></div> <br/>
                        <div className="col-md-1 price">8,95</div>

                        <div className="col-md-5"><span className="subheader1">Tacos de pollo</span></div> <br/>
                        <div className="col-md-1 price">9,95</div>

                        <div className="col-md-5"><span className="subheader1">Quesadilla de ternera</span></div> <br/>
                        <div className="col-md-1 price">10,95</div>
                    </div> <br/><br/>

                    <div className="col-md-4"><span className="subheader2">De nuestra parilla</span></div>
                    <span className="text">Todos los platos vienen acompañados con patata fritas.</span> <br/><br/>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Hamburguesa original</span> <br/>
                        <span className="text">Deliciosa carne Angus, acompañada con queso Cheddar, lechuga, cebolla roja y tomate.</span></div>
                        <div className="col-md-1 price">12,95</div>

                        <div className="col-md-5"><span className="subheader1">Bacon dream</span> <br/>
                        <span className="text">Sabrosa hamburguesa con beicon crujente, queso Cheddar, tomate, cebolla y lechuga fresca.</span></div>
                        <div className="col-md-1 price">13,95</div>

                        <div className="col-md-5"><span className="subheader1">Tengo mucha hambre</span> <br/>
                        <span className="text">Doble hamburguesa con doble beicon, queso Cheddar, lechuga, cebolla roja y tomate.</span></div>
                        <div className="col-md-1 price">16,95</div>

                        <div className="col-md-5"><span className="subheader1">Hamburguesa vegetal</span> <br/>
                        <span className="text">Deliciosa hamburguesa de espinaca, queso Cheddar y especias. </span></div>
                        <div className="col-md-1 price">9,95</div>

                        <div className="col-md-5"><span className="subheader1">Costillas de barbacoa</span> <br/>
                        <span className="text">Costillas de cerdo con salsa barbacoa.</span></div>
                        <div className="col-md-1 price">15,95</div>

                        <div className="col-md-5"><span className="subheader1">Pechuga de pollo</span> <br/>
                        <span className="text">Sabrosa pechuga de pollo marinada a la plancha. </span></div>
                        <div className="col-md-1 price">12,95</div>
                    </div> <br/><br/>

                    <div className="row">
                        <div className="col-md-5"><span className="header1">Menu de niños</span></div>
                        <div className="col-md-1 price">6,95</div>
                    </div>

                    <div className="row">
                        <div className="col-md-6"><span className="subheader1">Nuggets de pollo con patatas fritas</span></div>              
                        <div className="col-md-6"><span className="subheader1">Mini quesadilla de pollo con patatas fritas</span></div>
                        <div className="col-md-6"><span className="subheader1">Pizza margarita</span></div>
                    </div> 

                    <div className="row">
                        <div className="divider">
                            <div className="col-md-12"></div>     
                                <div>
                                    <img className="icon" src={color_skull}/>
                                </div>
                        </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-md-4"><span className="header1">Nuestras tentaciones</span></div>
                    </div>

                    <div className="row">
                        <div className="col-md-5"><span className="subheader1">Brownie de chocolate con helado de vainilla</span></div>
                        <div className="col-md-1 price">5,50</div>                        

                        <div className="col-md-5"><span className="subheader1">Helado variado</span> <br/>
                        <span className="text">Precio por bola</span></div>
                        <div className="col-md-1 price">2,00</div>

                        <div className="col-md-5"><span className="subheader1">Fresas frescas</span></div>
                        <div className="col-md-1 price">5,50</div>
                   </div>    

                   <div className="row">
                        <div className="divider">
                            <div className="col-md-12"></div>     
                                <div>
                                    <img className="icon" src={pink_skull}/>
                                </div>
                        </div>
                    </div>               
                </div> 


                
                    <div className="picture3">
                        <h3 className="container subheader" id="contacto">Contáctanos</h3>
                    </div>

                    <div className="container">            
                        <div className="row">
                                <div className="picture4 col-md-4"></div> <br/>
                                <div className="contact col-md-8">Apertura: <br/>
                                Lunes - Domingo: 13.00-24.00 <br/>
                                Tel: 631 59 43 89 | C. Moncayo 12, Fuengirola <br/><br/>
                                <span className="reserva" id="reservas">Reserva tu mesa aqui!</span>
                                </div>
                        </div>                   
                    </div>

                    {/* Footer */}
                    <div className="row">
                        <div className="infoFooter">
                            <a className="col-md-4" href="https://www.facebook.com/La-Casita-Mexicana-388850411724034"><img className="FB" alt="Facebook" src={fb_NY}/></a>
                            <a className="col-md-4" href="https://www.facebook.com/La-Casita-Mexicana-388850411724034"><img className="insta" alt="Facebook" src={instagram}/></a>
                            <a className="col-md-4" href="https://www.tripadvisor.se/Restaurant_Review-g315915-d17557456-Reviews-La_Casita_Mexicana-Fuengirola_Costa_del_Sol_Province_of_Malaga_Andalucia.html">
                            <img className="trip" alt="Tripadviser" src={tripadvisor_white}/></a>
                        </div>
                    </div>
                    
                     



            </div>                         
                           
        )
    }
}

export default Home;