import React from "react";
import "./contact.css";
import bricka_ljus from "../pictures/bricka_ljus.jpg";
import map from "../pictures/map.png";
import whatsapp from "../pictures/whatsapp.jpg";

export class Contact extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="contacto col-md-12">
                    <p>Opening hours: <br/>
                    Monday - Sunday: 13.00 to 24.00 <br/>
                        TeL: 631 59 43 89 <br/>
                    Calle Moncayo 12, Fuengirola. </p>
                    <img className="map" src={map}/>
                </div>
               
            </div>
            


        )
    }
}

export default Contact;