import React from "react";
import "./aboutUs.css";
import chili from "../pictures/chili.jpg";

export class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <img className="foto" src={chili}/>
            </div>
            // <h1>Hi and welcome to La Casita Mexicana in Fuengirola. </h1>
            




        )
    }
}

export default AboutUs;