import React from "react";
import "./navBar.css";
import logo_NY from "../pictures/logo_NY.png";
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText} from 'reactstrap';
// import AboutUs from "../aboutUs/aboutUs";
// import Contact from "../src/components/contact/contact";
// import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

export class NavBar extends React.Component {
    constructor(props) {
                super(props);
            
            this.toggleNavbar = this.toggleNavbar.bind(this);
            this.state = {
                isOpen: false,
                collapsed: true,
            };
          };
        
        toggleNavbar () {
            this.setState({
                isOpen: !this.state.isOpen
            });
        };       
    
    
    render() {
        return (
                <div className="navbar-wrapper">
                <Navbar color="black" light expand="sm">
                <NavbarBrand className="nonVisible" href="/"></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>          
                        <NavItem>
                            <NavLink href="#nosotros">Sobre nosotros</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#menu">Menu</NavLink>
                        </NavItem>
                        <NavItem  className="desktopVisible">
                            <img className="logo" src={logo_NY}/>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#contacto">Contacto</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#reservas">Reservas</NavLink>
                        </NavItem>                                  
                    </Nav>
                    </Collapse>
                </Navbar>
         </div>                               


        )

        }
    }
export default NavBar;