import logo_NY from './components/pictures/logo_NY.png';
import './App.css';
import React from 'react';
import NavBar from "../src/components/navBar/navBar";
import Home from "../src/components/home/home";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Translation } from 'react-i18next';
import './i18n';
import i18next from 'i18next';
import nachos from "../src/components/pictures/nachos.jpg";
import AboutUs from "../src/components/aboutUs/aboutUs";
import Contact from "../src/components/contact/contact";
import fajitas from "../src/components/pictures/fajitas.jpg";
import white_skull from "../src/components/pictures/white_skull.jpg";

export class App extends React.Component {
  render() {
    // i18next.changeLanguage('en');
    return (
      <div className="App">
          <header className="App-header">
              <NavBar/>                
          </header>     

          <div className="App-content">
            <Router>        
              <Switch>
                  <Route path="/aboutus" component={AboutUs} />
                  <Route path="/contact" component={Contact} /> 
                  <Route path="/" component={Home} />                   
              </Switch>      
            </Router>             
          </div>
      </div>              
    );
  }
}

export default App;
